// TODO this is just for 2023 Black Friday, we should make it more generic
export function isBlackFridayPromoEnabled(): boolean {
  // Pacific Timezone offset in miliseconds
  const pacificTimezoneOffset = -8 * 60 * 60 * 1000;
  const now = new Date();
  const startDate = new Date("2023-11-09T00:00:00.000Z");
  const endDate = new Date("2023-11-30T23:59:59.999Z");

  const pacificNow = new Date(now.getTime() + pacificTimezoneOffset);
  const pacificStartDate = new Date(startDate.getTime() + pacificTimezoneOffset);
  const pacificEndDate = new Date(endDate.getTime() + pacificTimezoneOffset);

  return pacificNow >= pacificStartDate && pacificNow <= pacificEndDate;
}
